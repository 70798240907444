import '@styles/front/index.scss';

import $ from 'jquery';
global.$ = $;
const Rails = require('rails-ujs');
Rails.start();

import Vue from 'vue';
import { configureRoutes } from './front/routes';
const routes = configureRoutes();

const bus = new Vue();
global.bus = bus;

const csrfProtection = function (xhr) {
  var token = $('meta[name="csrf-token"]').attr('content');
  if (token) xhr.setRequestHeader('X-CSRF-Token', token);
};

$.ajaxPrefilter(function (options, originalOptions, xhr) {
  if (!options.crossDomain) {
    csrfProtection(xhr);
  }
});

window.addEventListener('DOMContentLoaded', () => {
  const els = document.querySelectorAll('[data-js-action]');
  for (const el of els) {
    const keyString = el.getAttribute('data-js-action');
    const keys = keyString.split(',');
    for (const key of keys) {
      const callback = routes.get(key.trim());
      if (callback) {
        callback();
      }
    }
  }
});

var _window = $(window),
  _header_inner = $('.global-header-inner'),
  _header_menu = $('.header-menu'),
  _header_search = $('.search'),
  heroBottom = 0;

_window.on('scroll', function () {
  if (_window.scrollTop() > heroBottom) {
    _header_inner.addClass('fixed');
    _header_menu.addClass('fixed');
    _header_search.addClass('fixed');
  } else {
    _header_inner.removeClass('fixed');
    _header_menu.removeClass('fixed');
    _header_search.removeClass('fixed');
  }
});
_window.trigger('scroll');

$('.menu-trigger').on('click', function () {
  $(this).toggleClass('active');
  $('.sp-header-menu').toggleClass('show');
});
$('.search-trigger').on('click', function () {
  $(this).children('i').toggleClass('fa-times');
  $(this).children('i').toggleClass('fa-search');
  $('.sp-search').toggleClass('show');
});
