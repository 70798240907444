export default () => {
  const $view = $('.posts-show');
  const post = $view.data('post');

  $.get({
    url: `/api/posts/${post.id}`,
  });

  $('.toc-open-link').click((e) => {
    e.preventDefault();
    $('.post-toc').removeClass('toc-close');
  });
  $('.toc-close-link').click((e) => {
    e.preventDefault();
    $('.post-toc').addClass('toc-close');
  });

  // $("[data-part-id]").each((idx, elm) => {
  //     if (idx !== 0) {
  //         $(elm).addClass('hide');
  //     }
  // })

  // $('#read-more').click(e => {
  //     const elm = $('#read-more');
  //     const page = elm.data('current-page');
  //     const nextPage = parseInt(page, 10) + 1;
  //     $(`[data-part-id=${nextPage}]`).removeClass('hide');
  //     elm.data('current-page', nextPage);
  //     $('#current-page').text(nextPage);

  //     if (nextPage === parseInt(elm.data('total-page'), 10)) {
  //         $("[data-readmore]").hide();
  //     }
  // })
};
